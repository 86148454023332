import React, { Component } from 'react';
import Typewriter from 'typewriter-effect';
import pic from '../images/pic.png'

class Intro extends Component {
    state = {  } 
    render() { 
        return (
            <div class="grid grid-cols-1 gap-4">
                <div class="place-self-center"><img src={pic} alt="profile pic" class='w-48 mt-6'/></div>
                <div class="place-self-center text-center text-[rgb(49,255,90)] font-mono font-medium text-xl mx-4">
                    <Typewriter
                        options={{
                            cursor: '\u2588',
                            loop: true
                        }}
                        onInit={(typewriter) => {
                            typewriter
                                .typeString('Hello Wold')
                                .pauseFor(1000)
                                .deleteChars(4)
                                .pauseFor(300)
                                .typeString('World!')
                                .deleteAll()
                                .pauseFor(1000)
                                .typeString('Welcome to my portfolio site!')
                                .pauseFor(2000)
                                .start()
                        }}
                    />
                </div>
                <div class="place-self-center text-white font-medium text-center mx-4">My name is Naoki (NAH-OH-KEY) Tominaga (he/him/his)</div>
                <div class="place-self-center text-white font-medium text-center mx-4">I'm still building this page out, but for now check out my Github and add me on Linkedin down below!</div>
            </div>
        );
    }
}
 
export default Intro;