import './App.css';
import Intro from './components/Intro';
import Footer from './components/Footer';

function App() {
  return (
    <div class="bg-slate-900 h-screen sm:grid sm:place-content-center">
      <Intro/>
      <Footer/>
    </div>
  );
}

export default App;
