import React, { Component } from 'react';
import LinkedinLogo from '../images/linkedin.png'
import GithubLogo from '../images/github.png'

class Footer extends Component {
    
    render() { 
        return (
            // <div class="absolute bottom-0 inset-x-0 flex justify-end">
            <div class="flex justify-center">
                <a href="https://www.linkedin.com/in/naokitominaga/" target="_blank" rel="noreferrer noopener">
                    <img src={LinkedinLogo} alt="link to my linkedin profile" class="w-8 h-auto my-8 mx-9"/>
                </a>
                <a href="https://github.com/nrtominaga" target="_blank" rel="noreferrer noopener">
                    <img src={GithubLogo} alt="link to my github profile" class="w-8 h-auto my-8 mx-9"/>
                </a>
            </div>
        );
    }
}
 
export default Footer;